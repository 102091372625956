
@import "../../variables.less";
@import "../../resource/common.less";

@currentWidth: 500px;
.camera {
	margin-top: @smallSpace;
	height: 370px;
	flex-shrink: 0;
}

.page {
	margin-top: @smallSpace;
}
